// sport button
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

// not use for the moment
function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function eraseCookie(name) {
    document.cookie = name+'=; Max-Age=-99999999;';
}

function populateUI() {

  // let selectedSports = JSON.parse(localStorage.getItem('sports'));
  let encodedSports = getCookie('sports');
  // Kite%2CSurf to Kite,Surf
  let selectedSports = decodeURIComponent(encodedSports).split(",");

  // let selectedSports = (getCookie('sports').split(","));
  let sports = document.querySelectorAll('.sport')
  let test // array of sport
  if (selectedSports !== null && selectedSports.length > 0) // si array existe
    {sports.forEach(element => {
      // pour chaque ligne, est ce que je retrouve la valeur dans l'array des sports selectionnés
      test = selectedSports.find(e => e === element.value);
      if (test !== undefined) {
        element.setAttribute('checked',true);
      }
      //si oui changer la valeur de l'attribut
    })};
}

  function setSportsCookie(sportList) {
    var cookie_val = sportList;
    setCookie('sports',cookie_val, 30) ;
  }

// create a string of sports selected in localStorage
function createString() {
  let sports = document.getElementsByClassName('sport')
  let sportString = '';
  let separator = '';
  Array.from(sports).forEach(element => {
    if (element.checked == true) {
      sportString += separator + element.value;
      separator = ',';
    }
  });
  // console.log(sportList)
  // console.log(JSON.stringify(sportList));
  // localStorage.setItem('sports', JSON.stringify(sportList));
  if (sportString.length == 0) {
    eraseCookie("sports")
  } else {
    setSportsCookie(sportString)
  };
}

// sport

populateUI()

var checkboxUsed = 0

function incrementCheckboxUsed() {
  checkboxUsed += 1;
}

 var checkboxes = document.getElementsByClassName('sport');
 for (var i = 0; i < checkboxes.length; i++) {
   checkboxes[i].addEventListener('click', createString);
   checkboxes[i].addEventListener('click', incrementCheckboxUsed);
 }


// lancer une requete apres selection des sports
// sélectionner le bouton
var button = document.getElementById('sport-button');

// créer un nouveau MutationObserver
var observer = new MutationObserver(function(mutations) {
  // boucler à travers les mutations
  mutations.forEach(function(mutation) {
    // vérifier si l'attribut aria-expanded a changé
    if (mutation.attributeName === 'aria-expanded') {
      // exécuter du code en fonction de la nouvelle valeur de l'attribut
      if (button.getAttribute('aria-expanded') === 'false') {

        if (checkboxUsed != 0) {
          var url = window.location.pathname;
              // rediriger vers la nouvelle URL
          window.location.assign(url);
        }
      }
    }
  });
});

// configurer l'observer pour surveiller les changements de l'attribut aria-expanded du bouton
observer.observe(button, { attributes: true, attributeFilter: ['aria-expanded'] });
